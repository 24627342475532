<template>
  <div class="auth">
    <div class="auth__header">
      <a href="https://leasing-trade.ru" class="auth__header__link" target="_blank">Вернуться на сайт</a>
      <div class="auth__logo">
        <img src="/logo.png" alt="Лизинг трейд"/>
      </div>
      <a href="https://leasing-trade.ru" class="auth__header__link auth__header__link--hidden">Вернуться на сайт</a>
    </div>
    <div class="auth__body">
      <form class="auth__form">
        <h1 class="auth__form__title">Регистрация</h1>
        <div class="auth__form__input">
          <label for="inn">ИНН</label>
          <input type="text" v-model="inn" id="inn" placeholder="ИНН организации">
        </div>
        <div class="auth__form__actions">
          <button class="btn" @click.prevent="regPartner" type="submit">Регистрация</button>
          <router-link :to="{ name: 'auth'}">Уже есть аккаунт</router-link>
        </div>
      </form>
      <div class="auth__body__back">
        <RegBack/>
      </div>
    </div>
  </div>
</template>

<script>
import REG_PARTNER from '@/graphql/mutations/RegPartner.gql'
import RegBack from '../components/svg/RegBack'

export default {
  name: 'Registration',
  components: { RegBack },
  data: () => {
    return {
      inn: ''
    }
  },
  methods: {
    async regPartner ({ target }) {
      if (this.inn.length !== 10 && this.inn.length !== 12) {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'ИНН должен содержать 10 или 12 символов'
        })
        return
      }
      if (!target.validity.valid) return
      await this.$apollo.mutate({
        mutation: REG_PARTNER,
        variables: {
          inn: this.inn
        },
        update: (store, { data: { regPartner } }) => {
          if (regPartner) {
            this.$notify({
              group: 'lsg-notify',
              duration: 10000,
              text: 'Вам на email отправлено письмо с инструкциями по входу в систему'
            })
            this.$router.push('/auth')
          } else {
            this.$notify({
              group: 'lsg-notify',
              duration: 10000,
              text: 'К сожалению мы не нашли Вас в системе. Если Вы впервые регистрируетесь, то заполните форму ниже'
            })
            this.$store.state.partnerInn = this.inn
            this.$router.push('/registration/sms')
          }
        }
      })
    }
  }
}
</script>
